.tableBody td {
  background-color: var(--white);
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding: 4px 16px;
  white-space: nowrap;
  vertical-align: middle;
}

.tableBody tr:hover td {
  background-color: #001d6808;
}