.tableCellVoting {
  display: flex;
  align-items: center;
  gap: 4px;
}

.tableCellAddress,
.tableCellLink {
  display: flex;
  align-items: center;
}

.tableCellLink {
  gap: 6px;
}

.tableCellAddress {
  gap: 4px;
  max-width: 180px;
}

.tableCellAddress div,
.tableCellAddress span,
.tableCellLink div,
.tableCellLink span {
  color: var(--dark-blue);
  font-size: 1rem;
}

.tableCellAddress:hover div,
.tableCellAddress:hover span,
.tableCellLink:hover div,
.tableCellLink:hover span {
  color: var(--blue);
}

.tableCellAddress svg {
  min-width: 16px;
  min-height: 16px;
}

.tableCellAddress:hover svg {
  fill: var(--blue);
}

.tableCellEncrypted {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}